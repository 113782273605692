<template>
  <auth-card :btn-text="$t('authentication.log-in')"
    btn-classes="full-width"
    :btn-primary="false"
    :show-close-btn="true"
    @btn-click="$router.push('/')">
    <template #description>
      <el-dialog :visible.sync="showProcessingDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :center="true"
        top="4vh"
        width="fit-content">
        <div class="text-align-center">
          <img src="@/assets/loader.gif"
            :alt="$t('images.loading-logo')">
          <h4>{{ $t('authentication.setting-up') }}</h4>
        </div>
      </el-dialog>

      <el-form ref="signupForm"
        class="text-align-left"
        :model="signupForm"
        :rules="signupRules"
        label-position="top">
        <el-form-item :label="$t('general.first-name')"
          prop="firstName">
          <el-input v-model="signupForm.firstName"
            :placeholder="$t('general.first-name')"
            autocomplete="given-name"
            name="firstName" />
        </el-form-item>

        <el-form-item :label="$t('general.last-name')"
          prop="lastName">
          <el-input v-model="signupForm.lastName"
            :placeholder="$t('general.last-name')"
            autocomplete="family-name"
            name="lastName" />
        </el-form-item>

        <el-form-item :label="$t('company.company-trading-name')"
          prop="tradingName">
          <el-input v-model="signupForm.tradingName"
            :placeholder="$t('company.company-trading-name')"
            name="tradingName"
            autocomplete="off">
            <template #append>
              <el-popover placement="top"
                :title="$t('company.company-trading-name')"
                :content="$t('tooltips.company-trading-name')"
                width="400"
                trigger="hover">
                <span slot="reference">
                  <i class="el-icon-info color-info"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('general.email')"
          prop="email">
          <el-input v-model="signupForm.email"
            :placeholder="$t('general.email')"
            name="email"
            autocomplete="email"
            type="email" />
        </el-form-item>

        <el-form-item :label="$t('general.password')"
          prop="password"
          data-name="signupPassword">
          <el-input v-model="signupForm.password"
            :placeholder="$t('general.password')"
            name="password"
            type="password"
            autocomplete="off">
            <template #append>
              <el-popover placement="top"
                :title="$t('authentication.password-must-include')"
                width="300"
                trigger="hover">
                <div class="text-align-left">
                  <ul class="pl-2">
                    <li>{{ $t('authentication.at-least-8-char') }}</li>
                    <li>{{ $t('authentication.at-least-one-number') }}</li>
                    <li>{{ $t('authentication.at-least-one-symbol') }}</li>
                    <li>{{ $t('authentication.at-least-one-lower-case-letter') }}</li>
                    <li>{{ $t('authentication.at-least-one-upper-case-letter') }}</li>
                  </ul>
                </div>
                <span slot="reference">
                  <i class="el-icon-info color-info"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('authentication.confirm-password')"
          prop="confirmPassword">
          <el-input v-model="signupForm.confirmPassword"
            :placeholder="$t('authentication.confirm-password')"
            name="confirmPassword"
            autocomplete="off"
            type="password" />
        </el-form-item>

        <el-form-item prop="agreement">
          <el-checkbox id="agreeToTerms"
            v-model="signupForm.agreement"
            name="agreeToTerms">
            <span class="terms">
              {{ $t('authentication.agree-to') }}
              <a href="https://www.megaport.com/legal/privacy-policy/"
                target="_blank"
                rel="noopener">{{ $t('general.privacy-policy') }}</a>,
              <a href="https://www.megaport.com/legal/site-terms"
                target="_blank"
                rel="noopener">{{ $t('general.site-terms') }}</a>,
              <a href="https://www.megaport.com/legal/acceptable-use-policy/"
                target="_blank"
                rel="noopener">{{ $t('general.acceptable-use') }}</a>
            </span>
          </el-checkbox>
        </el-form-item>

        <el-button type="primary"
          data-name="create-account"
          class="full-width"
          @click="register">
          {{ $t('authentication.create-account') }}
        </el-button>
      </el-form>

      <h5 class="or">
        <span>{{ $t('authentication.or') }}</span>
      </h5>
    </template>
  </auth-card>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import AuthCard from '@/components/ui-components/AuthCard.vue'
import { passStrength } from '@/utils/passwordStrength.js'
import { validatePassword } from '@/validators.js'
import { showModalAlert } from '../AuthHelpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { captureEvent } from '@/utils/analyticUtils'
import sdk from '@megaport/api-sdk'

export default {
  name: 'SignupForm',

  components: {
    'auth-card': AuthCard,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      showProcessingDialog: false,
      signupForm: {
        firstName: '',
        lastName: '',
        tradingName: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreement: false,
      },
      signupRules: {
        firstName: { required: true, message: this.$t('validations.required', { thing: 'First name' }), trigger: 'blur' },
        lastName: { required: true, message: this.$t('validations.required', { thing: 'Last name' }), trigger: 'blur' },
        tradingName: { required: true, message: this.$t('validations.required', { thing: 'Company trading name' }), trigger: 'blur' },
        email: [
          { required: true, message: this.$t('validations.required', { thing: 'Email' }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('validations.required', { thing: 'Password' }), trigger: 'blur' },
          { validator: this.validatePasswordStrength, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: this.$t('validations.required', { thing: 'Confirm Password' }), trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' },
        ],
        agreement: { required: true, validator: this.validateBooleanTrue, trigger: 'change' },
      },
    }
  },

  computed: {
    ...mapGetters('Auth', ['isDistributorAccount', 'homePage']),

    /**
     * Show email verification screen if env variable is enabled
     */
    showEmailVerification() {
      return process.env.VUE_APP_DISABLE_AUTH_EMAIL_VERIFICATION === 'false'
    },
  },

  mounted() {
    this.prefillForm()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    ...mapActions('Services', ['getMyServices']),
    /**
     * Prefill form with cookie values coming from megaport.com
     */
    prefillForm() {
      const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))
      const cookieValue = JSON.parse(decodeURIComponent(cookies.get('formData')))

      // Assign pre-filled values to form
      this.signupForm.firstName = cookieValue?.f
      this.signupForm.lastName = cookieValue?.l
      this.signupForm.tradingName = cookieValue?.c
      this.signupForm.email = cookieValue?.e
    },
    /**
     * Validate agreement checkbox is checked
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateBooleanTrue(_rule, value, callback) {
      if (typeof value !== 'boolean' || !value) {
        callback(new Error(this.$t('validations.terms-conditions')))
      } else {
        callback()
      }
    },
    /**
     * Validate password strength
     * @param {Object} rule
     * @param {string} value
     * @param {Function} callback
     */
    validatePasswordStrength(rule, value, callback) {
      const passwordAnalysis = passStrength(this.signupForm.password)
      validatePassword(rule, value, callback, passwordAnalysis)
    },
    /**
     * Validate password and confirm password fields match
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateConfirmPassword(_rule, value, callback) {
      if (value !== this.signupForm.password) {
        callback(new Error(this.$t('validations.confirm-password-mismatch')))
      } else {
        callback()
      }
    },
    /**
     * Sign up the user
     */
    register() {
      this.$refs['signupForm'].validate(valid => {
        // Prevent submitting when form is invalid or while there is already a request in progress ENG-7872
        if (!valid || this.showProcessingDialog) {
          this.notifyBad({
            title: this.$t('authentication.signup-failed'),
          })
          return
        }

        const signupPayload = {
          firstName: this.signupForm.firstName,
          lastName: this.signupForm.lastName,
          companyName: this.signupForm.tradingName,
          email: this.signupForm.email,
          password: this.signupForm.password,
        }

        this.showProcessingDialog = true
        sdk.instance
          .register(signupPayload)
          .then(async data => {
            // Automatically sign in the registered user if email validaiton is disabled
            if (!this.showEmailVerification) {
              this.$store.commit('Auth/login', data)
              window.newRegister = true
              // Required so that the requisite flags are set up, even though we know it's
              // going to be an empty list.
              await this.getMyServices()

              // Get all the other actions that run on login to run
              await this.$store.dispatch('onLogin', null, { root: true })

              this.$router.push(this.homePage)
            }

            this.showProcessingDialog = false

            if (this.$appConfiguration.isProduction) {
              captureEvent('signup.create-account.click')
            }

            // Save signup email to local storage
            localStorage.setItem('_username', this.signupForm.email || '')

            // Go to verify email page if email validation is enabled
            if (this.showEmailVerification) {
              this.$router.push('/verify-email')
            }
          })
          .catch(e => {
            this.showProcessingDialog = false

            if (!e.handled) {
              if (e.data?.message) {
                showModalAlert(e.data.message, this.$t('authentication.error-registering'), 'error')
              } else {
                showModalAlert(this.$t('authentication.unknown-error-registering'), this.$t('authentication.error-registering'), 'error')
                captureSentryError(e)
              }
            }
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-icon-info {
  font-size: 1.6rem;
  line-height: initial;
}

::v-deep .el-dialog {
  padding: 3rem 10rem;

  .el-dialog__header {
    padding: 0;
  }
}
</style>
